import Slideshow from '../components/slideshow.js';

export default class Homepage {
  constructor() {
    this.pinnedItineraries = JSON.parse(localStorage.getItem('data-saved-itineraries'));

    this.itinerariesData = undefined;

    this.menuItinerariesEl = document.querySelector('[data-itineraries]');
    this.pinnedItinerariesContainerEl = document.querySelector('[data-saved-itineraries]');

    this._initMenu();
  }

  async _initMenu() {
    // this.itinerariesData = await this._addItinerariesMenuOptions();

    this._fillGlobalOptions();

    this._fillPagesLinks();

    this._initAccordions();

    document.querySelector(`[data-tagline-lang="${window.language}"]`).classList.add('show');

    if(this.pinnedItineraries) {
      this.pinnedItineraries.forEach(pinnedItinerary => {
        const itineraryId = 'itinerary-'+ pinnedItinerary[window.language].id;
        document.querySelector('[data-itinerary="'+ itineraryId +'"] .fa-thumbtack').classList.add('.fas');
        document.querySelector('[data-itinerary="'+ itineraryId +'"] .fa-thumbtack').classList.remove('.far');

        this._addPinnedItenerary(itineraryId, pinnedItinerary[window.language]);
      });
    }

    document.querySelectorAll('[data-itinerary]').forEach(el => {
      const itinerary = el.getAttribute('data-itinerary');
      const currentItineraryId = itinerary.replace('itinerary-', '');
      const thumbtackEl = el.querySelector('.fa-thumbtack');

      if(this.pinnedItineraries?.length > 0) {
        let itineraryIsPinned = false;
        for(const pinnedItinerary of this.pinnedItineraries) {
          if(pinnedItinerary[window.language].id == currentItineraryId) {
            itineraryIsPinned = true;
            break;
          }
        }

        if(itineraryIsPinned)
          el.classList.add('pinned');
        else
          el.classList.remove('pinned');
      }

      thumbtackEl.addEventListener('click', e => this._thumbtackClickHandler(e.currentTarget, itinerary));
    });
  }

  _fillGlobalOptions() {
    return fetch(window.ENV.API_BASE_PATH +'/global-option/?populate=deep&locale='+ window.language)
      .then(response => response.json())
      .then(data => {
        if(document.querySelector('[data-home-gpx]') && data.data.attributes.gpx_file.data)
          document.querySelector('[data-home-gpx]').setAttribute('href', window.ENV.API_ASSETS_BASE_PATH + data.data.attributes.gpx_file.data.attributes.url)

        if(document.querySelector('[data-home-sos]') && data.data.attributes.sos_number)
          document.querySelector('[data-home-sos]').setAttribute('href', 'tel:'+data.data.attributes.sos_number)

        if(data.data.attributes.bg_images.length > 0 && document.querySelector('[data-home-bg]')) {
          if(data.data.attributes.bg_images.length == 1) {
            document.querySelector('[data-home-bg]').innerHTML = this._getImageHtml(data.data.attributes.bg_images[0]);
          } else {
            document.querySelector('[data-home-bg]').innerHTML = this._getSlideshowHtml(data.data.attributes.bg_images);
            new Slideshow(document.querySelector('[data-home-bg] .ma-slideshow'));
          }
        }

      });
  }

  _fillPagesLinks() {
    const PAGES_FILTER = [
      'sobre',
      'about',
      'acerca',

      'como-chegar',
      'how-to-get-there',
      'como-llegar',

      'sinaletica',
      'signage',
      'senalizacion',

      'conselhos-de-seguranca',
      'safety-advice',
      'consejo-de-seguridad',

      'comunidade',
      'community',
      'comunidad'
    ];

    return fetch(window.ENV.API_BASE_PATH +'/pages/?populate=*&locale='+ window.language)
      .then(response => response.json())
      .then(data => {
        if(document.querySelector('[data-pages]') && data.data.length > 0) {
          data.data.forEach(page => {
            let match;
            if(match = /segmento?-(.)/.exec(page.attributes.slug)) {
              const segmentEl = document.querySelector(`[data-segment="${match[1]}"]`)
              if(segmentEl) {
                segmentEl.innerText = page.attributes.title;
                segmentEl.setAttribute('href', `page/?slug=${page.attributes.slug}`);
              }
            }

            if(PAGES_FILTER.includes(page.attributes.slug) || page.attributes.slug.match(/segmento?-/))
              return;

            document.querySelector('[data-pages]').innerHTML +=
              `<li class="nav-menu__opt nav-menu__opt--flex">
                <a class="nav-menu__opt-text" href="page/?slug=${page.attributes.slug}" data-menu-opt="page">${page.attributes.title}</a>
              </li>`;
          });

          this._initAccordions();
        }
      });
  }

  _getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  _addItinerariesMenuOptions() {
    return fetch(window.ENV.API_BASE_PATH +'/itineraries/?populate=*&locale='+ window.language)
      .then(response => response.json())
      .then(data => {
        const menuOptionsHtml = data.data.reduce((accumulator, itinerary) => {
          const html =
            `<li class="nav-menu__opt" data-itinerary="itinerary-${itinerary.id}" data-color="${itinerary.attributes.color}">
              <div class="strip-color" style="background-color: ${itinerary.attributes.color}"></div>
              <div class="nav-menu__opt-text">
                <a class="nav-menu__opt-link" href="roteiro/?id=${itinerary.id}">${itinerary.attributes.name}</a>
                <span class="nav-menu__opt-pin">
                  <i class="fas fa-thumbtack"></i>
                </span>
              </div>
            </li>`;

          return accumulator + html;
        }, '');

        this.menuItinerariesEl.innerHTML = menuOptionsHtml;

        return data.data;
      })
      .catch(error => console.error('Erro:', error));
  }

  _initAccordions() {
    if(document.querySelectorAll('.ma-accordion').length > 0) {
      document.querySelectorAll('.ma-accordion').forEach((el, i) => {
        let accordion = undefined;
        if(el.getAttribute('id') === 'accordion-languages')
          accordion = new MaAccordion(el, true, null, null, null, null, () => $('#homepage').animate({ scrollTop: document.querySelector('#homepage').clientHeight}, 300));
        else
          accordion = new MaAccordion(el, true);

        if(i == document.querySelectorAll('.ma-accordion').length -1)
          document.body.classList.add('body-ready');
      });
    }
  }




  _addPinnedItenerary(itinerary, data) {
    const pinnedItineraryHtml =
      `<li class="nav-menu__opt pinned bg-color--itinerary-light" data-itinerary-pinned="${itinerary}">
        <div class="strip-color" style="background-color: ${data.color}"></div>
        <div class="nav-menu__opt-text">
          <a class="nav-menu__opt-link" href="roteiro/?id=${data.id}">
            ${data.title}
          </a>
          <span class="nav-menu__opt-pin">
            <i class="fas fa-thumbtack"></i>
          </span>
        </div>
      </li>`;

    this.pinnedItinerariesContainerEl.insertAdjacentHTML('beforeend', pinnedItineraryHtml);

    const thumbtackEl = document.querySelector('[data-itinerary-pinned="'+itinerary+'"] .fa-thumbtack');
    thumbtackEl.addEventListener('click', e => this._thumbtackClickHandler(e.currentTarget, itinerary));
  }

  _removePinnedItenerary(itinerary) {
    document.querySelector('[data-itinerary="'+itinerary+'"]').classList.remove('pinned');
    document.querySelector('[data-itinerary-pinned="'+itinerary+'"]').remove();
  }

  _thumbtackClickHandler(thumbtackEl, itinerary) {
    const optionEl = thumbtackEl.closest('.nav-menu__opt');

    const currentItineraryId = itinerary.replace('itinerary-', '');
    const currentItineraryData = this.itinerariesData.filter(el => el.id == currentItineraryId)[0];

    let itineraries = JSON.parse(localStorage.getItem('data-saved-itineraries')) || [];

    if(optionEl.classList.contains('pinned')) {
      optionEl.classList.remove('pinned');

      itineraries = itineraries.filter(itinerary => itinerary[window.language].id != currentItineraryId);

      localStorage.setItem('data-saved-itineraries', JSON.stringify(itineraries));
      this._removePinnedItenerary(itinerary);
    }
    else {
      optionEl.classList.add('pinned');

      let data = {};
      //current language itinerary
      data[currentItineraryData.attributes.locale] = {
        id: currentItineraryData.id,
        title: currentItineraryData.attributes.name,
        color: currentItineraryData.attributes.color,
      };
      //translated itinerary
      data[currentItineraryData.attributes.localizations.data[0].attributes.locale] = {
        id: currentItineraryData.attributes.localizations.data[0].id,
        title: currentItineraryData.attributes.localizations.data[0].attributes.name,
        color: currentItineraryData.attributes.localizations.data[0].attributes.color,
      };

      itineraries.push(data);
      localStorage.setItem('data-saved-itineraries', JSON.stringify(itineraries));

      this._addPinnedItenerary(itinerary, data[window.language]);
    }
  }


  _getSlideshowHtml(imagesData) {
    return `<div class="ma-slideshow" id="ma-slideshow-1">
              <div class="glide" data-glide-slideshow="data-glide-slideshow">
                <div class="glide__track" data-glide-el="track">
                  <ul class="glide__slides">
                  ${imagesData.reduce((acc, val) => acc + (
                    `<li class="glide__slide">
                      <div class="glide__bg" style="background-image: url(${window.ENV.API_ASSETS_BASE_PATH + val.image.data.attributes.url}); background-position: ${val.bg_x}% ${val.bg_y}%;"></div>
                    </li>`
                  ), '')}
                  </ul>
                </div>
                <div class="ma-slideshow__controls glide__arrows" data-glide-el="controls">
                  <button class="glide__arrow glide__arrow--prev" data-glide-dir="&lt;">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18.8 30.3" style="enable-background:new 0 0 18.8 30.3;" xml:space="preserve">
                      <polygon fill="#2A2827" points="16.3,30.3 18.8,27.5 5.4,15.1 18.8,2.7 16.3,0 0,15.1"></polygon>
                    </svg>
                  </button>
                  <button class="glide__arrow glide__arrow--next" data-glide-dir="&gt;">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18.8 30.3" style="enable-background:new 0 0 18.8 30.3;" xml:space="preserve">
                      <polygon fill="#2A2827" points="2.5,0 0,2.8 13.4,15.2 0,27.6 2.5,30.3 18.8,15.2"></polygon>
                    </svg>
                  </button>
                </div>
              </div>
            </div>`;
  }

  _getImageHtml(imageData) { //TODO add this code to Utils to avoid repetition
    return `<div class="fullwidth-image">
              <a data-pswp-width="${imageData.attributes.width}" data-pswp-height="${imageData.attributes.height}" data-pswp-src="${window.ENV.API_ASSETS_BASE_PATH + imageData.attributes.url}">
                <img class="lazyload" data-srcset="${this._getSrcsetImages(imageData.attributes)}" data-sizes="auto" width="${imageData.attributes.width}" height="${imageData.attributes.height}" src="${window.ENV.API_ASSETS_BASE_PATH + imageData.attributes.formats.small.url}"/>
              </a>
            </div>`;
  }

  _getSrcsetImages(imagesData) { //TODO add this code to Utils to avoid repetition
    let srcset = '';

    if(imagesData.formats.small)
      srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.formats.small.url} ${imagesData.formats.small.width}w, `;
    if(imagesData.formats.medium)
      srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.formats.medium.url} ${imagesData.formats.medium.width}w, `;
    if(imagesData.formats.large)
      srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.formats.large.url} ${imagesData.formats.large.width}w, `;

    srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.url} ${imagesData.width}w`;

    return srcset;
  }
}
