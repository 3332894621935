import { Loader } from "@googlemaps/js-api-loader"

import Slideshow from '../components/slideshow.js';

export default class Itinerary {
  constructor(pageEl, googleMapsOptions) {
    this.pageEl = pageEl;
    this.googleMapsOptions = googleMapsOptions;

    this.map = undefined;
    this.itinerariesData = undefined;

    this.converter = new showdown.Converter();

    const urlParams = new URLSearchParams(window.location.search);
    this.itineraryId = urlParams.get('id');

    const loader = new Loader({
      ...googleMapsOptions
    });

    let requestPath = window.ENV.API_BASE_PATH +'/itineraries?populate=deep&locale='+ window.language;

    if(this.itineraryId)
      requestPath = window.ENV.API_BASE_PATH +'/itineraries/'+this.itineraryId+'?populate=deep&locale='+ window.language;

    fetch(requestPath)
      .then(response => response.json())
      .then(data => {
        this.itinerariesData = data.data;

        loader.load().then(async () => this._initMap() );
      })
      .catch(error => console.error('Erro:', error));


    this.pageEl.querySelector('.point-info__container').addEventListener('click', () => this._closePointInfo());
    this.pageEl.querySelector('.point-info__close-icon').addEventListener('click', () => this._closePointInfo());

    this.pageEl.querySelector('.point-info__content').addEventListener('click', e => e.stopPropagation());
  }

  async _initMap () {
    const { Map } = await google.maps.importLibrary('maps');
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary('marker');

    let centerPoint, firstPoint;

    if(this.itinerariesData[0].attributes.points.data.length > 0) {
      firstPoint = this.itinerariesData[0].attributes.points.data[0]; //temporary TODO add center point to Strapi
      centerPoint = (this.itinerariesData[0].attributes.center_latitude && this.itinerariesData[0].attributes.center_longitude)
        ? { lat: parseFloat(this.itinerariesData[0].attributes.center_latitude), lng: parseFloat(this.itinerariesData[0].attributes.center_longitude) }
        : { lat: parseFloat(firstPoint.attributes.latitude), lng: parseFloat(firstPoint.attributes.longitude) };
    }

    this.map = new Map(this.pageEl.querySelector('#googlemaps-map'), {
      center: centerPoint ? centerPoint : { lat: 40.1970713, lng: -8.4082688 },
      zoom: this.itinerariesData[0].attributes.center_zoom || 16,
      fullscreenControl: false,
      mapId: '6167a4adba0000d5',
    });

    this._getGlobalOptions().then(homeData => {
      let kmlLayer;
      let kmlLayerSecondary;

      if(homeData.attributes.kml_file?.data) {
        kmlLayer = new google.maps.KmlLayer(window.ENV.API_ASSETS_BASE_PATH + homeData.attributes.kml_file.data.attributes.url, {
          suppressInfoWindows: true,
          preserveViewport: true
        });
      }

      if(homeData.attributes.kml_file_secondary?.data) {
        kmlLayerSecondary = new google.maps.KmlLayer(window.ENV.API_ASSETS_BASE_PATH + homeData.attributes.kml_file_secondary.data.attributes.url, {
          suppressInfoWindows: false,
          preserveViewport: true
        });        
      }

      if(kmlLayer){
        google.maps.event.addListener(kmlLayer, "status_changed", function() {
          if(kmlLayerSecondary)
          kmlLayerSecondary.setMap(this.map);
        });
        kmlLayer.setMap(this.map);
      }
    });

    this.itinerariesData.map(itinerary => {
      const itineraryCoordinates = itinerary.attributes.points.data.map(point => {
        return {
          lat: parseFloat(point.attributes.latitude),
          lng: parseFloat(point.attributes.longitude),
          id: point.id,
          color: point.attributes.category.data?.attributes.color,
          text_color: point.attributes.category.data?.attributes.text_color,
          img: point.attributes.category.data?.attributes.marker.data ? window.ENV.API_ASSETS_BASE_PATH + point.attributes.category.data?.attributes.marker.data?.attributes.url : undefined,
        };
      });

      itineraryCoordinates.forEach((point, index) => {
        this._addMarker(point, index, AdvancedMarkerElement, PinElement);
      });
    });
  }


  _getGlobalOptions() {
    return fetch(window.ENV.API_BASE_PATH +'/global-option/?populate=deep&locale='+ window.language)
      .then(response => response.json())
      .then(data => data.data);
  }

  _addMarker(point, index, AdvancedMarkerElement, PinElement) {
    const markerImg = document.createElement('img');
    if(point.img)
      markerImg.src = point.img;

    const pinElement = new PinElement({
      glyph: `${index + 1}`,
      glyphColor: point.text_color == 'dark' ? 'black' : 'white',
      background: point.color || '#6A998E',
      borderColor: 'white',
      scale: 1.1,
    });

    const marker = new AdvancedMarkerElement({
      position: { lat: point.lat, lng: point.lng },
      map: this.map,
      content: point.img ? markerImg : pinElement.element,
    });
    marker.addListener('click', () => this._openPointInfo(index, point.id));
  }

  _openPointInfo(index, id) {
    this.pageEl.querySelector('.point-info').classList.add('point-info--open');
    this._getPointInfo(index, id);
  }

  _closePointInfo() {
    this.pageEl.querySelector('.point-info').scrollTo({
      top: 0,
      behavior: "smooth"
    });
    this.pageEl.querySelector('.point-info').classList.remove('point-info--open');
    this.pageEl.querySelector('.point-info').scrollTo(0,0);
  }

  _getPointInfo(index, id) {
    fetch(window.ENV.API_BASE_PATH +'/points/'+id+'?populate=deep&locale='+ window.language)
      .then(response => response.json())
      .then(data => {
        this._addPointInfo(index, data.data);
      })
      .catch(error => console.error('Erro:', error));

  }

  _addPointInfo(index, pointData) {
    this.pageEl.querySelector('[data-point-content]').innerHTML =
      `<div class="point-info__heading">
        <div class="point-info__num">
          ${ pointData.attributes.category.data?.attributes.marker.data ?
            `<img src="${window.ENV.API_ASSETS_BASE_PATH + pointData.attributes.category.data?.attributes.marker.data?.attributes.url}">`
            :
            `<svg viewBox="0 0 24 24"><defs><style>.cls-1{fill:none;}.cls-2{fill:${pointData.attributes.category.data?.attributes.color || '#6A998E'};}.cls-3{font-size:7px;fill:${pointData.attributes.category.data?.attributes.text_color === 'dark' ? 'black' : 'white'};font-family:OpenSansRoman-Bold, Open Sans;font-weight:700;}</style></defs><path class="cls-1" d="M0,0H24V24H0Z"/><path class="cls-2" d="M12,2A7,7,0,0,0,5,9c0,5.25,7,13,7,13s7-7.75,7-13A7,7,0,0,0,12,2Z"/><text class="cls-3"  x="12" y="12" text-anchor="middle">${index + 1}</text></svg></span>`
          }
        </div>
        <div class="point-info__heading-titles">
          ${pointData.attributes.category.data ? `<h5>${pointData.attributes.category.data?.attributes.name}</h5>` : ''}
          <h2>${pointData.attributes.name}</h2>
        </div>
      </div>
      ${pointData.attributes.type ? `<h5>${pointData.attributes.type}</h5>`: ''}
      <ul class="point-info__details">
        ${pointData.attributes.maps_link ?
           `<a class="point-info__detail-item" href="${pointData.attributes.maps_link}" target="_blank">
              <i class="fas fa-map"></i>Google Maps
            </a>` : ''}
        ${pointData.attributes.link ?
           `<a class="point-info__detail-item" href="${pointData.attributes.link}" target="_blank">
              <i class="fas fa-link"></i>Website
            </a>` : ''}
      </ul>
      ${pointData.attributes.description ?
       `<div class="point-info__description" data-point-description="data-point-description">${this.converter.makeHtml(pointData.attributes.description)}</div>`
        : ''}
      ${pointData.attributes.notes ?
       `<div class="point-info__notes">${this.converter.makeHtml(pointData.attributes.notes)}</div>`
        : ''}


      ${pointData.attributes.images.data ? (
        pointData.attributes.images.data?.length == 1 ? this._getImageHtml(pointData.attributes.images.data[0]) : this._getSlideshowHtml(pointData.attributes.images.data)
      ) : ''}`;

    if(pointData.attributes.images.data?.length == 1)
      this._initPhotoSwipe(this.pageEl.querySelector('[data-point-content] [data-pswp-src]'));
    else if(pointData.attributes.images.data?.length > 0)
      new Slideshow(this.pageEl.querySelector('[data-point-content] .ma-slideshow'));
  }

  _getImageHtml(imageData) { //TODO add this code to Utils to avoid repetition
    return `<div class="fullwidth-image">
              <a data-pswp-width="${imageData.attributes.width}" data-pswp-height="${imageData.attributes.height}" data-pswp-src="${window.ENV.API_ASSETS_BASE_PATH + imageData.attributes.url}">
                <img class="lazyload" data-srcset="${this._getSrcsetImages(imageData.attributes)}" data-sizes="auto" width="${imageData.attributes.width}" height="${imageData.attributes.height}" src="${window.ENV.API_ASSETS_BASE_PATH + imageData.attributes.formats.small.url}"/>
              </a>
            </div>`;
  }

  _getSlideshowHtml(imagesData) {
    return `<div class="ma-slideshow" id="ma-slideshow-1">
              <div class="glide" data-glide-slideshow="data-glide-slideshow">
                <div class="glide__track" data-glide-el="track">
                  <ul class="glide__slides">
                  ${imagesData.reduce((acc, val) => acc + (
                    `<li class="glide__slide">
                      <a data-pswp-width="${val.attributes.width}" data-pswp-height="${val.attributes.height}" data-pswp-src="${window.ENV.API_ASSETS_BASE_PATH + val.attributes.url}">
                        <img class="lazyload" data-srcset="${this._getSrcsetImages(val.attributes)}" data-sizes="auto" width="${val.attributes.width}" height="${val.attributes.height}" src="${window.ENV.API_ASSETS_BASE_PATH + val.attributes.formats.small.url}"/>
                      </a>
                    </li>`
                  ), '')}
                  </ul>
                </div>
                <div class="ma-slideshow__controls glide__arrows" data-glide-el="controls">
                  <button class="glide__arrow glide__arrow--prev" data-glide-dir="&lt;">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18.8 30.3" style="enable-background:new 0 0 18.8 30.3;" xml:space="preserve">
                      <polygon fill="#2A2827" points="16.3,30.3 18.8,27.5 5.4,15.1 18.8,2.7 16.3,0 0,15.1"></polygon>
                    </svg>
                  </button>
                  <button class="glide__arrow glide__arrow--next" data-glide-dir="&gt;">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18.8 30.3" style="enable-background:new 0 0 18.8 30.3;" xml:space="preserve">
                      <polygon fill="#2A2827" points="2.5,0 0,2.8 13.4,15.2 0,27.6 2.5,30.3 18.8,15.2"></polygon>
                    </svg>
                  </button>
                </div>
              </div>
            </div>`;
  }

  _getSrcsetImages(imagesData) {
    let srcset = '';

    if(imagesData.formats.small)
      srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.formats.small.url} ${imagesData.formats.small.width}w, `;
    if(imagesData.formats.medium)
      srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.formats.medium.url} ${imagesData.formats.medium.width}w, `;
    if(imagesData.formats.large)
      srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.formats.large.url} ${imagesData.formats.large.width}w, `;

    srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.url} ${imagesData.width}w`;

    return srcset;
  }

  _initPhotoSwipe(el) {//TODO add this code to Utils to avoid repetition - componente igual
    const pswpElement = document.querySelectorAll('.pswp')[0];

    const options = {
      index: 0,
      mainClass : 'pswp--minimal--dark',
      barsSize : {top:10,bottom:10},
      captionEl : true,
      fullscreenEl : false,
      shareEl : false,
      bgOpacity : 0.65,
      tapToClose : true,
      tapToToggleControls : false,
      history: false
    };

    el.addEventListener('click', (e) => {
      e.preventDefault();

      //if is dragging, don't open photoswipe
      if(el.getAttribute('draggable') == 'false')
        return;

      const pswpItems = [];

      options.index = 1;

      const item = {
        src : el.getAttribute('data-pswp-src'),
        w : el.getAttribute('data-pswp-width'),
        h : el.getAttribute('data-pswp-height')
      };

      pswpItems.push(item);

      const gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, pswpItems, options);
      gallery.init();
    });
  }
}
