export default class LanguageSwitcher {
  constructor() {
    this.language = undefined;

    if (localStorage.getItem('language'))
      this._setLanguage(localStorage.getItem('language'));
    else
      this._setLanguage(navigator.language.includes('pt') ? 'pt' : 'en', true);

    this._updateLabels();

    document.querySelectorAll('[data-language]').forEach(el => {
      el.addEventListener('click', e => {
        localStorage.setItem('language', el.getAttribute('data-language'));
        location.reload();
      });
    });
  }

  _updateLabels() {
    const labels = {
      itineraries: {
        pt: 'Roteiros',
        en: 'Itineraries',
      },
      'itinerary-and-points': {
        pt: 'Rota e Pontos de interesse',
        en: 'Itinerary and Points of interest',
        es: 'Ruta y Puntos de Interés',
      },
      about: {
        pt: 'Sobre',
        en: 'About',
        es: 'Acerca',
      },
      map: {
        pt: 'Mapa',
        en: 'Map',
        es: 'Mapa',
      },
      language: {
        pt: 'Língua ',
        en: 'Language ',
        es: 'Idioma ',
      },
      'how-to-get-there': {
        pt: 'Como chegar',
        en: 'How to get there',
        es: 'Cómo llegar'
      },
      'safety-advice': {
        pt: 'Regras de conduta e segurança',
        en: 'Safety Advice',
        es: 'Consejo de Seguridad'
      },
      signage: {
        pt: 'Sinalética',
        en: 'Signage',
        es: 'Señalización'
      },
      community: {
        pt: 'Comunidade',
        en: 'Community',
        es: 'Comunidad'
      },
      segments: {
        pt: 'Segmentos',
        en: 'Segments',
        es: 'Segmentos'
      },
      gpx: {
        pt: 'Download',
        en: 'Download',
        es: 'Descarregar'
      }
    }

    const slugs = {
      about: {
        pt: 'sobre',
        en: 'about',
        es: 'acerca'
      },
      'how-to-get-there': {
        pt: 'como-chegar',
        en: 'how-to-get-there',
        es: 'como-llegar'
      },
      'safety-advice': {
        pt: 'conselhos-de-seguranca',
        en: 'safety-advice',
        es: 'consejo-de-seguridad'
      },
      signage: {
        pt: 'sinaletica',
        en: 'signage',
        es: 'senalizacion'
      },
      community: {
        pt: 'comunidade',
        en: 'community',
        es: 'comunidad'
      },
      gpx: {
        pt: 'gpx',
        en: 'gpx_en',
        es: 'gpx_es'
      }
    }

    if (document.querySelector('[data-menu-opt="map"]'))
      document.querySelector('[data-menu-opt="map"]').innerHTML = labels['map'][window.language];

    if (document.querySelector('[data-menu-opt="itineraries"]'))
      document.querySelector('[data-menu-opt="itineraries"]').innerHTML = labels['itineraries'][window.language];

    if (document.querySelector('[data-menu-opt="itinerary-and-points"]'))
      document.querySelector('[data-menu-opt="itinerary-and-points"]').innerHTML = labels['itinerary-and-points'][window.language];

    if (document.querySelector('[data-page-title="itinerary-and-points"]'))
      document.querySelector('[data-page-title="itinerary-and-points"]').innerHTML = labels['itinerary-and-points'][window.language];


    if (document.querySelector('[data-menu-opt="about-label"]')) {
      document.querySelector('[data-menu-opt="about-label"]').innerHTML = labels['about'][window.language];
    }

    if (document.querySelector('[data-menu-opt="about-slug"]')) {
      document.querySelector('[data-menu-opt="about-slug"]').setAttribute('href', `page/?slug=${slugs['about'][window.language]}`);
    }

    if (document.querySelector('[data-menu-opt="language"]'))
      document.querySelector('[data-menu-opt="language"]').innerHTML = labels['language'][window.language];

    if (document.querySelector('[data-menu-opt="how-to-get-there"]')) {
      document.querySelector('[data-menu-opt="how-to-get-there"]').innerHTML = labels['how-to-get-there'][window.language];
      if(slugs['how-to-get-there'][window.language])
        document.querySelector('[data-menu-opt="how-to-get-there"]').setAttribute('href', `page/?slug=${slugs['how-to-get-there'][window.language]}`);
    }

    if (document.querySelector('[data-menu-opt="safety-advice"]')) {
      document.querySelector('[data-menu-opt="safety-advice"]').innerHTML = labels['safety-advice'][window.language];
      if(slugs['safety-advice'][window.language])
        document.querySelector('[data-menu-opt="safety-advice"]').setAttribute('href', `page/?slug=${slugs['safety-advice'][window.language]}`);
    }

    if (document.querySelector('[data-menu-opt="signage"]')) {
      document.querySelector('[data-menu-opt="signage"]').innerHTML = labels['signage'][window.language];
      if(slugs['signage'][window.language])
        document.querySelector('[data-menu-opt="signage"]').setAttribute('href', `page/?slug=${slugs['signage'][window.language]}`);
    }

    if (document.querySelector('[data-menu-opt="community"]')) {
      document.querySelector('[data-menu-opt="community"]').innerHTML = labels['community'][window.language];
      if(slugs['community'][window.language])
        document.querySelector('[data-menu-opt="community"]').setAttribute('href', `page/?slug=${slugs['community'][window.language]}`);
    }

    if (document.querySelector('[data-menu-opt="segments"]'))
      document.querySelector('[data-menu-opt="segments"]').innerHTML = labels['segments'][window.language];

    if (document.querySelector('[data-menu-opt="gpx"]')) {
      document.querySelector('[data-menu-opt="gpx"]').innerHTML = labels['gpx'][window.language];
      if(slugs['gpx'][window.language])
        document.querySelector('[data-menu-opt="gpx"]').setAttribute('href', `page/?slug=${slugs['gpx'][window.language]}`);
    }
  }

  _setLanguage(newLanguage) {
    this.language = newLanguage;

    window.language = this.language;

    localStorage.setItem('language', this.language);

    if (document.querySelector('[data-current-language]'))
      document.querySelector('[data-current-language]').innerHTML = this.language;

    const languageUpdateEvent = new Event('language-update');
  }
}
